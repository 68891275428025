<template>
  <div
    class="lightbox"
    :id="`gallery-${galleryId}`"
    style="width: 100%; height: 100%; position: relative;"
  >
    <div
      style="height: 100%; width: 100%;"
      class="lightbox-gallery"
      ref="lightboxItems"
      v-show="false"
    >
      <a
        :href="item.src"
        target="_blank"
        :data-pswp-width="item.width"
        :data-pswp-height="item.height"
        rel="noreferrer"
        v-for="(item) in loadedImages"
        :key="`item-${item.src}`"
      >
        <img
          :src="item.src"
        />
      </a>
    </div>
  </div>
</template>
<script>
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm'
import 'photoswipe/dist/photoswipe.css'

const getUniqId = () => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let autoId = ''
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length))
  }

  return autoId
}

export default {
  props: ['value'],
  name: 'LightboxDialog',
  data: () => ({
    lightbox: null,
    galleryId: getUniqId(),
    loadedImages: []
  }),
  mounted() {
    this.loadImages(this.model)
      .then(data => {
        this.loadedImages = data
      })
  },
  destroyed() {
    this.lightbox = null
    window.pswp = null
  },
  computed: {
    model() {
      const { value } = this
      const result = Array.isArray(value) ? [...value] : [value].map(src => ({ 
        src 
      }))
      return result
    }
  },
  methods: {
    async loadImages(urls) {
      const load = url => {
        return new Promise(res => {
          const img = new Image()

          img.src = url
          const cb = (err = null) => {
            const el = err && null || img
            res({
              el,
              src: url,
              width: el && el.width || 0,
              height: el && el.height || 0
            })
          }
          img.onload = () => cb()
          img.onerror = err => cb(err)
        })
      }
      const responses = await Promise.allSettled(urls.map(url => url.src).map(load))
      const images = responses.map(res => res.value)

      return images
    },
  },
  watch: {
    async loadedImages(to) {
      if (to && to.length) {
        const children = 'a'
        const pswpModule = await (() => import('photoswipe'))
        this.lightbox = new PhotoSwipeLightbox({
          dataSource: to,
          gallery: '#gallery-' + this.galleryId,
          children,
          clickToCloseNonZoomable: false,
          bgOpacity: 0,
          spacing: 0.5,
          wheelToZoom: true,
          close: false,
          padding: { top: 100, bottom: 100, right: 100, left: 100 },
          preload: [0],
          pswpModule,
          appendToEl: this.$root.$el.querySelector('.v-dialog--active.v-dialog--fullscreen')
        })

        this.lightbox.init()
        this.lightbox.on('openingAnimationEnd', () => this.$emit('opened'))

        this.lightbox.on('close', () => {
          this.lightbox = null
          window.pswp = null
          this.$emit('closed')
        })
        
        setTimeout(() => {
          console.log(this.lightbox)
          this.lightbox.loadAndOpen(0)
        }, 0)
        // console.log(window.pswp)
      }
    }
  }
}
</script>
<style lang="scss">
.pswp__top-bar {
  display: none;
}
</style>